<template>
  <v-container>
    <v-card v-if="!loading && dish" class="elevation-0 transparent">
      <dish-detail :dish="dish"></dish-detail>
    </v-card>
  </v-container>
</template>

<script>
import DishDetail from './DishDetail'
import { mapGetters } from 'vuex'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'

export default {
  mixins: [FoodMixin],
  components: {
    DishDetail
  },
  mounted() {
    let slug = this.$route.params.slug
    console.log('Loading product:', slug)
    this.$store.dispatch('getDishInfoFromSlug', slug)
  },
  computed: {
    ...mapGetters({
      user: 'user',
      dish: 'dish',
      loading: 'loading'
    })
  }
}
</script>
